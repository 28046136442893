.similarTemplates {
    @media (--screen-lt-md) {
        inline-size: 100vw;
        max-inline-size: 100vw;
        overflow: scroll;
        scroll-snap-type: x mandatory;
        scroll-padding: var(--space-24);
        scrollbar-width: none;
        margin-block-start: calc(-1 * var(--space-24));
        padding-block-start: var(--space-24);
        padding-block-end: var(--space-24);
    }
}

.templatesGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(260px, 100%), 1fr));
    gap: var(--space-12);
    margin: 0 var(--space-24);

    li {
        scroll-snap-align: start;

        > div {
            block-size: 100%;
        }
    }

    @media (--screen-lt-md) {
        inline-size: fit-content;
        padding: 0 var(--space-24);
        grid-template-columns: repeat(3, 327px);
    }
}

.dots {
    display: flex;
    justify-content: center;
}
