.colorSecondary {
    color: var(--display-onlight-secondary);
}

.hug {
    max-inline-size: 40rem;
}

.seoSection {
    margin-inline: auto;
    text-align: center;
}
