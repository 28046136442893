.figure {
    position: relative;
    margin: 0;
    padding: var(--space-48) 0 var(--space-128);
    text-align: center;

    @media (--screen-lt-md) {
        padding-block-end: 0;
    }

    blockquote {
        max-inline-size: 50rem;
        margin-inline: auto;
    }
}

.illustration {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    pointer-events: none;
    max-inline-size: 76.4375rem;
    inline-size: 100%;
    height: auto;

    @media (--screen-lt-md) {
        position: relative;
    }
}
