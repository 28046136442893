.masonry {
    --column-width: minmax(min(18rem, 100%), 1fr);
    --spacing: var(--space-12);

    display: grid;
    grid-template-columns: repeat(auto-fit, var(--column-width));
    grid-template-rows: masonry;
    justify-content: center;
    grid-gap: var(--spacing);

    > * {
        inline-size: var(--column-width);
        align-self: start;
    }
}
