.markup {
    figure,
    img {
        max-inline-size: 100%;
        block-size: auto;
    }
    a {
        font-size: inherit;
    }
}
