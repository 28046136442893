.header {
    display: flex;
    justify-content: space-between;
}

.search {
    max-inline-size: 28.125rem;
    margin-inline: auto;
    margin-block-start: var(--space-24);
    margin-block-end: var(--space-32);
}

.searchIllustration {
    display: block;
    margin-inline: auto;
    margin-block-start: var(--space-64);
}
