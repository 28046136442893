.modal {
    display: flex;
    flex-direction: column;
    gap: var(--space-16);
}

.heading {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &:has(p) {
        justify-content: space-between;
    }
}

.actions {
    display: flex;
    flex-direction: column;
    gap: var(--space-12);
}

.backdrop {
    position: fixed;
    inset: 0;
    z-index: var(--z-index-modal);
    overflow: hidden;
    background-color: var(--color-black-07);
    -webkit-backdrop-filter: blur(4px);
    opacity: 0;
    transition-property: opacity, backdrop-filter;
    transition-timing-function: var(--animation-ease-base);
    transition-duration: var(--animation-duration-150);
    backdrop-filter: blur(0);

    &[data-enter] {
        opacity: 1;
        backdrop-filter: blur(4px);
    }
}

.popup {
    position: fixed;
    inset: 0;
    margin: auto;
    block-size: fit-content;
    inline-size: var(--popup-inline-size, fit-content);
    overflow: auto;
    max-inline-size: calc(100% - var(--space-32));
    max-block-size: calc(100% - var(--space-32));
    background-color: white;
    border-radius: var(--border-radius-xl);
    box-shadow: var(--shadow-xl);
    z-index: var(--z-index-modal);

    transform-origin: center;
    opacity: 0;
    transition-property: opacity, transform;
    transition-timing-function: var(--animation-ease-base);
    transition-duration: var(--animation-duration-150);
    transform: scale(0.95);

    &:focus-visible {
        outline: none;
    }

    &[data-enter] {
        opacity: 1;
        transform: scale(1);
    }
}
